import { FrontPage } from "../components/types";
import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import * as styles from "../components/variables";

const Wrap = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const ImageContainer = styled.div`
  position: absolute;
  top: 2em;
  left: 2em;
  width: 100%;
  height: 100%;
  @media (max-width: ${styles.breakpointMobile}) {
    left: 0;
  }
`;

const TextContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  font-size: 2.2em;
  color: yellow;
`;

export default function Home({ data }: { data: FrontPage }) {
  const posts = data.allWpPage.edges;

  return (
    <div className="container">
      <Layout>
        <div>
          {posts.map((post) => {
            return (
              <div key={post.node.frontpage.entry.id}>
                <Link to={`/${post.node.frontpage.entry.contentType.node.name}/${post.node.frontpage.entry.slug}`}>
                  <Wrap>
                    {post.node.frontpage.entry.cpt.images ? (
                      <ImageContainer>
                        <GatsbyImage
                          image={
                            post.node.frontpage.entry.cpt.images[0].image ?
                            post.node.frontpage.entry.cpt.images[0].image.localFile
                              .childImageSharp.gatsbyImageData : null
                          }
                          alt="images"
                        />
                      </ImageContainer>
                    ) : null}
                    <TextContainer>
                      <h1>{post.node.frontpage.entry.title}</h1>
                      {post.node.frontpage.entry.cpt.content ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: post.node.frontpage.entry.cpt.content,
                          }}
                        />
                      ) : null}
                    </TextContainer>
                  </Wrap>
                </Link>
              </div>
            );
          })}
        </div>
      </Layout>
    </div>
  );
}

export const Head = () => <Seo title="FOR" />;

export const pageQuery = graphql`
  query {
    allWpPage(filter: { title: { eq: "Frontpage" } }) {
      edges {
        node {
          frontpage {
            entry {
              ... on WpMagazine {
                id
                slug
                title
                cpt {
                  content
                  documentation
                  images {
                    caption
                    image {
                      id
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            layout: CONSTRAINED
                            quality: 100
                            sizes: "100vw"
                          )
                        }
                      }
                    }
                  }
                }
                contentType {
                  node {
                    name
                  }
                }
              }
              ... on WpShow {
                id
                slug
                title
                cpt {
                  content
                  documentation
                  images {
                    caption
                    image {
                      id
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            layout: CONSTRAINED
                            quality: 100
                            sizes: "100vw"
                          )
                        }
                      }
                    }
                  }
                }
                contentType {
                  node {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
